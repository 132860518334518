<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable disabled @change="changeCenter()" class="w-100 mt-3" clearable
                           v-model="filter.center_id"
                           placeholder="Trung tâm">
                  <el-option
                      v-for="item in centers"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable @change="changeBranch()" class="w-100 mt-3" clearable
                           v-model="filter.branch_id"
                           placeholder="Chi nhánh">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable class="w-100 mt-3" clearable
                           v-model="filter.type"
                           placeholder="Loại học">
                  <el-option
                      v-for="item in types"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>

            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable class="w-100 mt-3" clearable v-model="filter.course_start" placeholder="Đầu vào">
                  <el-option
                      v-for="item in course_levels"
                      :key="item.id"
                      :label="`${item.course_type ? item.course_type.name + ':' : ''}` + `${item.name}`"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col v-if="this.filter.type != 2" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <el-select filterable class="w-100 mt-3" clearable v-model="filter.course_end" placeholder="Đầu ra">
                  <el-option
                      v-for="item in course_levels"
                      :key="item.id"
                      :label="`${item.course_type ? item.course_type.name + ':' : ''}` + `${item.name}`"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit()"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>

            </el-row>
            <el-row class="row" style="margin-top: 15px" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div style="margin-bottom: 5px" class="w-100 text-size-16">Tìm thấy
                  {{ is_remainng ? 0 : this.center_total }} kết
                  quả phù hợp:
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div v-if="is_remainng" class="w-100 text-size-16 color-danger-900">Dưới đây là kết quả gợi ý dành cho
                  bạn
                </div>
              </el-col>
              <el-col v-for="(item, index) in listCourse" :key="index" class="col-sm-12 col-md-6 col-lg-3">
                <CourseItem v-if="!item.combo"
                            :course="item"
                            :is_sale_view="true"
                >
                </CourseItem>
                <CourseItemCombo v-else
                                 :course="item"
                                 :is_sale_view="true"
                >
                </CourseItemCombo>
              </el-col>
              <el-col class="text-center mt-2" v-if="this.page < this.last_page" :xs="24" :sm="24" :md="24" :lg="24"
                      :xl="24">
                <a href="javascript:void(0)" @click="addPage()">Xem thêm</a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TU_VAN_COURSE_LIST} from "@/core/services/store/tuVan.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {mapGetters} from "vuex";
import {GET_CENTERS} from "@/core/services/store/center/center.module";
import {GET_BRANCHES_ALL} from "@/core/services/store/center/branch.module";
import {GET_COURSE_LEVELS} from "@/core/services/store/course/courseLevel.module";
import CourseItem from "./CourseItem";
import CourseItemCombo from "./CourseItemCombo";

export default {
  name: "AdviseCourse",
  components: {
    CourseItemCombo,
    CourseItem,
  },
  data() {
    return {
      loading: false,
      provinces: [],
      districts: [],
      centers: [],
      branches: [],
      course_levels: [],

      types: [
        {
          id: 1,
          name: 'Học thật'
        },
        {
          id: 2,
          name: 'Học thử'
        },
      ],
      listCourse: [],
      center_total: 0,
      is_remainng: 0,
      is_submit: false,
      last_page: 0,
      page: 1,

      filter: {
        province_id: '',
        district_id: '',
        center_id: '',
        branch_id: '',
        type: '',
        course_start: '',
        course_end: ''
      }
    }
  },
  async mounted() {
    this.filter.center_id = this.$route.query.center_id;
    await this.getCenters();
    await this.getBranches();
    this.getCourseLevel();
    this.callApi();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tìm kiếm khóa học", icon: ''}
    ]);
  },
  methods: {
    getCenters() {
      let center_id = this.$route.query.center_id;
      this.$store.dispatch(GET_CENTERS, {
        center_id: center_id,
        status: 1,
        all: 1
      }).then((response) => {
        if (!response.error) {
          this.centers = response.data.data;
          this.filter.center_id = parseInt(center_id);
          this.loading = false;
        }
      });
    },

    getBranches() {
      this.$store.dispatch(GET_BRANCHES_ALL, {
        center_id: this.filter.center_id,
        limit: 1000000,
        status: 1
      }).then((response) => {
        if (response.status === 422) {
          this.$message({type: 'warning', message: `${response.message}`, showClose: true});
        } else {
          this.branches = response.data.data;
        }
        this.loading = false;
      });
    },
    getCourseLevel() {
      this.$store.dispatch(GET_COURSE_LEVELS, {
        center_id: this.filter.center_id,
        branch_id: this.filter.branch_id,
        all: 1
      }).then((response) => {
        if (response.status === 422) {
          this.$message({type: 'warning', message: `${response.message}`, showClose: true});
        } else {
          this.course_levels = response.data;
        }
        this.loading = false;
      });
    },

    changeProvince() {
      this.filter.district_id = '';
      this.filter.center_id = '';
      this.filter.branch_id = '';
      this.getDistricts();
      this.changeDistrict();
    },

    changeDistrict() {
      this.filter.center_id = '';
      this.filter.branch_id = '';
      this.getCenters();
      this.changeCenter();
    },

    changeCenter(event) {
      console.log(event, 'hihihi')
      let type = 'center';
      this.filter.branch_id = '';
    },

    changeBranch() {
      this.getCourseLevel();
    },

    addPage() {
      this.page = this.page + 1;
      this.loadMore();
    },
    submit() {
      if (this.filter.course_start == this.filter.course_end && this.filter.course_start) {
        this.$notify({
          title: "Warning",
          message: `Vui lòng chọn đầu vào và đầu ra khác nhau.`,
          type: 'error'
        })
        return;
      }
      this.loading = true;
      this.callApi();
    },
    callApi() {
      this.page = 1;
      this.$store.dispatch(TU_VAN_COURSE_LIST, {
        center_id: this.filter.center_id,
        branch_id: this.filter.branch_id,
        type: this.filter.type,
        course_start: this.filter.course_start,
        course_end: this.filter.course_end
      }).then((response) => {
        if (!response.error) {
          this.last_page = response.data.class_combo.last_page;
          this.listCourse = response.data.class_combo.data;
          this.center_total = response.data.class_combo.total;
          this.is_remainng = response.data.is_remainng;
        }
        this.loading = false;
      })
    },
    loadMore() {
      this.$store.dispatch(TU_VAN_COURSE_LIST, {
        center_id: this.filter.center_id,
        branch_id: this.filter.branch_id,
        type: this.filter.type,
        course_start: this.filter.course_start,
        course_end: this.filter.course_end,
        page: this.page
      }).then((response) => {
        if (!response.error) {
          this.last_page = response.data.class_combo.last_page;
          this.listCourse.push(...response.data.class_combo.data);
          this.center_total = response.data.class_combo.total;
          this.is_remainng = response.data.is_remainng;
        }
        this.loading = false;
      })
    },
  },
  computed: {
    ...
        mapGetters(['currentUser'])
  }
}
</script>

<style scoped>

</style>