<template>
  <div class="edu-card combo mt-2">
    <div class="edu-card-header">
      <div class="edu-image">
        <div class="teacher-card">
          <h3>{{ course.name + ' - ' + course.branch_name }}</h3>
        </div>
      </div>
    </div>
    <div class="edu-card-body">
      <h3 :title=getName() class="title-single"><span>{{ getName() }}</span></h3>
      <div class="detail">
        <img :src="publicPath + `/media/images/center-detail/center.svg`" alt="">
        <router-link
            :to="{ path: '/detail-center/'+ course.center_id }"
            style="text-decoration: unset"
        >{{ course.center_name ? course.center_name : '' }}
        </router-link>
      </div>
      <div class="detail">
        <img :src="publicPath + `/media/images/center-detail/position-marker.svg`" alt="">
        <span>{{ course.branch_address }}</span>
      </div>
      <div>
        <p class="detail">
          <img :src="publicPath + `/media/images/center-detail/opening-times.svg`" alt="">
          <b> {{
              course.first_class_room ? 'Ngày khai giảng: ' + formatDate(course.first_class_room.start_date) : 'Chưa có lớp sắp khai giảng'
            }}</b>
        </p>
        <div v-if="course.first_class_room">
          <p class="detail">
            <img style="color: #0a0a0a" :src="publicPath + `/media/images/center-detail/icon_teacher_save.png`" alt="">
            <b>Giảng viên: {{ course.first_class_room.teacher_name }}</b>
          </p>
          <p class="detail">
            <img style="color: #0a0a0a" :src="publicPath + `/media/images/center-detail/ico-parameter-2.png`" alt="">
            <b v-if="course.first_class_room.type != 'trial'">
              Đã đăng ký vào lớp: {{ course.first_class_room.current_student }} / {{ course.first_class_room.number_student }}
            </b>
            <b v-else>Đã đăng ký vào lớp: {{ course.first_class_room.current_student }}</b>
          </p>
        </div>
        <p class="price">{{ getPricePromotion(course) }}<small class="old-fees"><span v-if="course.sale">{{ course.fee | formatVND }}</span></small></p>
      </div>
    </div>
    <div class="edu-card-footer d-flex justify-content-between">
      <p class="students">
        <img :src="publicPath + `/media/images/center-detail/user amount.svg`" alt="">
        <b>Có {{ course.so_dang_ky }} học viên đăng ký</b>
      </p>
      <router-link
          class="btn btn-info  view-detail float-right"
          :to="{ path: '/advise/detail-course/'+ course.id , query : {
              customer_id : this.$route.query.customer_id,
              course_id : course.id,
              center_id : course.center_id,
              branch_id : course.branch_id,
              classroom_id : course.first_class_room ? course.first_class_room.id : 0,
              payment : this.$route.query.payment
            } }"
          style="text-decoration: unset"
      >Xem chi tiết
      </router-link>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tuvanvien.scss';
import {formatVND} from "../../../../core/filters";
import moment from "moment-timezone";

export default {
  name: "CourseItem",
  props: {
    course: {
      type: Object,
      default: () => {
        return {};
      }
    },
    is_sale_view: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },
    getPricePromotion(item) {
      if (item.fee) {
        let price = item.fee - (item.sale * item.fee / 100);
        return formatVND(price);
      } else {
        return '0đ';
      }
    },
    getRating(rating) {
      return parseInt(parseFloat(rating).toFixed(0));
    },
    getTextStars(start) {
      return '(' + parseInt(parseFloat(start).toFixed(0)) + '/5)';
    },
    getName() {
      return this.course.first_class_room ? this.course.first_class_room.name : this.course.name + ' - ' + this.course.branch_name;
    },
    getTitleCourse() {
      return this.center.name;
    }
  }
}
</script>

<style>
.teacher-card {
  background: #886ab5 !important;
}

.view-detail {
  background: #886ab5 !important;
  border: #886ab5 !important;
}

</style>