<template>
  <div class="edu-card combo mt-2">
    <div class="edu-card-header">
      <div class="edu-image">
        <div class="teacher-card">
          <h3>{{ course.name }}</h3>
        </div>
      </div>
    </div>
    <div class="edu-card-body">
      <el-tooltip class="btn-tooltip-hover" :content="`${course.detail_name}`" placement="bottom" effect="light">
        <el-button>{{ course.name }}</el-button>
      </el-tooltip>
      <div class="detail">
        <img :src="publicPath + `/media/images/center-detail/center.svg`" alt="">
        <router-link
            :to="{ path: '/detail-center/'+ course.center_id }"
            style="text-decoration: unset"
        >{{ course.center ? course.center.name : '' }}
        </router-link>
      </div>
      <div class="detail">
        <img :src="publicPath + `/media/images/center-detail/position-marker.svg`" alt="">
        <span>{{ course.branch_address }}</span>
      </div>
      <div>
        <p class="detail">
          <img :src="publicPath + `/media/images/center-detail/opening-times.svg`" alt="">
          <b>{{
              !course.class_start_date ? 'Chưa có lớp sắp khai giảng' : 'Ngày khai giảng: ' + formatDate(course.class_start_date)
            }}</b>
        </p>
        <p class="price">{{ course.sum - course.value  | formatVND }}<small
            class="old-fees">
          <span>{{ course.sum | formatVND }}</span>
        </small>
        </p>
      </div>
    </div>
    <div class="edu-card-footer d-flex justify-content-end">
      <router-link
          class="btn btn-info float-right view-detail"
          :to="{ path: '/advise/detail-course-combo/'+ course.id , query : {
              customer_id : this.$route.query.customer_id,
              promotion_id : course.id,
              classroom_id : course.first_class_room ? course.first_class_room.id : 0,
              payment : this.$route.query.payment,
              center_id : this.$route.query.center_id,
              branch_id : this.course.branch_id
            } }"
          style="text-decoration: unset"
      >Xem chi tiết
      </router-link>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tuvanvien.scss';
import {formatVND} from "../../../../core/filters";
import moment from "moment-timezone";

export default {
  name: "CourseItemCombo",
  props: {
    course: {
      type: Object,
      default: () => {
        return {};
      }
    },
    is_sale_view: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },
    getPricePromotion(item) {
      if (item.fee) {
        let price = item.fee - (item.sale * item.fee / 100);
        return formatVND(price);
      } else {
        return '0đ';
      }
    },
    getRating(rating) {
      return parseInt(parseFloat(rating).toFixed(0));
    },
    getTextStars(start) {
      return '(' + parseInt(parseFloat(start).toFixed(0)) + '/5)';
    },
    getName(first_classroom) {
      if (!this.is_sale_view) {
        return first_classroom ? first_classroom.name : this.center.name;
      }
      return first_classroom ? first_classroom.name + ' - ' + this.center.branch_name : this.center.name + ' - ' + this.center.branch_name;
    },
    getTitleCourse() {
      return this.center.name;
    }
  }
}
</script>

<style>
.teacher-card {
  background: #886ab5 !important;
}

.view-detail {
  background: #886ab5 !important;
  border: #886ab5 !important;
}

.btn-tooltip-hover {
  border: unset;
}

.btn-tooltip-hover:hover {
  background: unset;
  border: unset;
}

</style>