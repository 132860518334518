var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edu-card combo mt-2"},[_c('div',{staticClass:"edu-card-header"},[_c('div',{staticClass:"edu-image"},[_c('div',{staticClass:"teacher-card"},[_c('h3',[_vm._v(_vm._s(_vm.course.name))])])])]),_c('div',{staticClass:"edu-card-body"},[_c('el-tooltip',{staticClass:"btn-tooltip-hover",attrs:{"content":`${_vm.course.detail_name}`,"placement":"bottom","effect":"light"}},[_c('el-button',[_vm._v(_vm._s(_vm.course.name))])],1),_c('div',{staticClass:"detail"},[_c('img',{attrs:{"src":_vm.publicPath + `/media/images/center-detail/center.svg`,"alt":""}}),_c('router-link',{staticStyle:{"text-decoration":"unset"},attrs:{"to":{ path: '/detail-center/'+ _vm.course.center_id }}},[_vm._v(_vm._s(_vm.course.center ? _vm.course.center.name : '')+" ")])],1),_c('div',{staticClass:"detail"},[_c('img',{attrs:{"src":_vm.publicPath + `/media/images/center-detail/position-marker.svg`,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.course.branch_address))])]),_c('div',[_c('p',{staticClass:"detail"},[_c('img',{attrs:{"src":_vm.publicPath + `/media/images/center-detail/opening-times.svg`,"alt":""}}),_c('b',[_vm._v(_vm._s(!_vm.course.class_start_date ? 'Chưa có lớp sắp khai giảng' : 'Ngày khai giảng: ' + _vm.formatDate(_vm.course.class_start_date)))])]),_c('p',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("formatVND")(_vm.course.sum - _vm.course.value))),_c('small',{staticClass:"old-fees"},[_c('span',[_vm._v(_vm._s(_vm._f("formatVND")(_vm.course.sum)))])])])])],1),_c('div',{staticClass:"edu-card-footer d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-info float-right view-detail",staticStyle:{"text-decoration":"unset"},attrs:{"to":{ path: '/advise/detail-course-combo/'+ _vm.course.id , query : {
            customer_id : this.$route.query.customer_id,
            promotion_id : _vm.course.id,
            classroom_id : _vm.course.first_class_room ? _vm.course.first_class_room.id : 0,
            payment : this.$route.query.payment,
            center_id : this.$route.query.center_id,
            branch_id : this.course.branch_id
          } }}},[_vm._v("Xem chi tiết ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }